import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import PageNav from '../components/homeNav/PageNav'
import '../style/CategoryTheme.css'
import '../style/Category.css'
import '../style/Category.mobile.css'
import '../widgets/Pagination.css'

import Drugicon from '../images/medicineicon.png'
import Footer from '../components/homeNav/footer/Footer'
import {
  AddToCartBtn2,
  CartIcon,
  StoreName,
} from '../components/med/medElements'
import Seo from '../components/seo'
import BotNav from '../components/bottomNav'
import useAuthState from '../stores/auth'
import useCartState from '../stores/cart'

Modal.setAppElement('#___gatsby')

function HitItem(hit, setSuccess) {
  const { user } = useAuthState()

  const { cart, setCart, increment } = useCartState()

  const addToCart = () => {
    const newCartProduct = {
      id: hit.id,
      productName: hit.productName,
      packSize: hit.packSize,
      discount: hit.discount,
      shop: hit.shop.name,
      sellingPrice: hit.sellingPrice,
      showPrice: hit.showPrice,
      customBrandName: hit.customBrandName,
      type: hit.type,
      unit: hit.unit,
      image: hit?.productImage?.replace(
        'https://res.cloudinary.com/tripleaim-software/image/upload/',
        'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_230,f_auto/',
      ),
      quantity: 1,
    }

    /* if (cloudinaryImage) {
      newCartProduct.prescription = cloudinaryImage.secure_url
    } */

    const cartIndex = cart.findIndex(item => item.id === hit.id)

    // not in cart
    if (cartIndex === -1) {
      setCart([...cart, newCartProduct])
      toast.success(`Item added: ${newCartProduct.productName}`)
    } else {
      increment(cartIndex)
      toast.success(
        `You know have ${cart[cartIndex].quantity}  ${newCartProduct.productName} in your cart `,
      )
    }
  }

  const goToProduct = (productName, _id) => {
    navigate(`/products/${productName.replace(/ /g, '-').toLowerCase()}/${_id}`)
  }

  const imageM =
    hit.productImage?.replace(
      'https://res.cloudinary.com/tripleaim-software/image/upload/',
      'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_184,f_auto/',
    ) || Drugicon

  const imageDesk =
    hit.productImage?.replace(
      'https://res.cloudinary.com/tripleaim-software/image/upload/',
      'https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_230,f_auto/',
    ) || Drugicon

  return (
    <article className="hit" style={{ cursor: 'pointer' }}>
      <div className="hit-info-container">
        {!!hit.discount.value && (
          <div
            style={{ color: 'green', fontSize: '1.5rem', fontWeight: 'bold' }}
          >
            -{hit.discount.value}% Off
          </div>
        )}
        <div onClick={() => goToProduct(hit.productName, hit.id)}>
          <ImgContainer>
            <div className="hit-img-container">
              <img className="hit-img" src={imageDesk} alt="medicine" />
            </div>
          </ImgContainer>
          <ImgContainerM>
            <div className="hit-img-container">
              <img className="hit-img" src={imageM} alt="medicine" />
            </div>
          </ImgContainerM>

          <h3 className="hit-category">
            {hit.type === 'pharmaceutical'
              ? hit.customBrandName
              : hit.productName}
          </h3>
          {hit.type === 'pharmaceutical' ? (
            <span className="genericName">{hit.genericName}</span>
          ) : (
            <span className="genericName">{hit.packSize}</span>
          )}
          <div
            css={`
              margin-top: 1rem;
              font-size: 1rem;
              margin-bottom: 1rem;
            `}
          >
            {hit.discount.value ? (
              <strong>
                Price:{' '}
                <span style={{ textDecoration: 'line-through', color: 'gray' }}>
                  Ksh {hit.sellingPrice}{' '}
                </span>
                Ksh{' '}
                {Math.round(
                  hit.sellingPrice * ((100 - hit.discount.value) / 100),
                )}{' '}
              </strong>
            ) : (
              <strong>
                Price:{' '}
                {hit.showPrice ? hit.sellingPrice : <span>On Request</span>}
              </strong>
            )}

            <div>
              <strong>
                Per:
                {hit.unit}
              </strong>{' '}
            </div>
          </div>
        </div>
        {user?.admin && (
          <StoreName
            to={`/pharmacy/${hit.shop.name.replace(/ /g, '-').toLowerCase()}`}
          >
            <div
              css={`
                margin-top: 1rem;
                font-size: 1rem;
                margin-bottom: 1rem;
              `}
            >
              <strong>
                {`${hit.shop.name.charAt(0).toUpperCase() +
                  hit.shop.name.slice(1).toLowerCase()
                  } - ${hit.shop.physicalAddress.street
                    .charAt(0)
                    .toUpperCase()}${hit.shop.physicalAddress.street
                      .slice(1)
                      .toLowerCase()}`}
              </strong>
            </div>
          </StoreName>
        )}
      </div>

      <footer>
        {hit.prescribed ? (
          <AddToCartBtn2
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() =>
              navigate(
                `/products/${hit.productName
                  .replace(/ /g, '-')
                  .toLowerCase()}/${hit.id}`,
              )
            }
          >
            {' '}
            Prescription{' '}
          </AddToCartBtn2>
        ) : (
          <AddToCartBtn2
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => addToCart()}
          >
            {' '}
            Add to cart <CartIcon />{' '}
          </AddToCartBtn2>
        )}
        <AddToCartBtn2
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
          onClick={() =>
            navigate(
              `/products/${hit.productName.replace(/ /g, '-').toLowerCase()}/${hit.id
              }`,
            )
          }
        >
          {' '}
          Details{' '}
        </AddToCartBtn2>
      </footer>
    </article>
  )
}

function Search({ location, pageContext }) {
  const containerRef = useRef(null)
  const headerRef = useRef(null)

  const isBrowser = typeof window !== 'undefined'

  const [selecteditems, setSelecteditems] = useState([])
  const [pharmacy, setPharmacy] = useState([])
  const [countyLoc, setCountyLoc] = useState([])
  const [estateLoc, setEstateLoc] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false)
  const [success, setSuccess] = useState('')

  const data2 = useStaticQuery(graphql`
    query SearchTemp {
      allProducts {
        edges {
          node {
            id
            productName
            batchNumber
            category
            sellingPrice
            customBrandName
            type
            expiry
            formulation
            genericName
            packSize
            prescribed
            strength
            supplier
            showProduct
            showPrice
            unit
            productImage
            score
            discount {
              type
              value
            }
            shop {
              _id
              name
              physicalAddress {
                county
                street
              }
            }
          }
        }
      }
    }
  `)

  const items = data2.allProducts.edges.map(item => item.node)

  const productName = pageContext.product

  const dataItemsR = items.filter(item => item.productName === productName)

  const preferredShop = process.env.GATSBY_PREFERRED_SHOP

  const dataItems = dataItemsR?.sort((a, b) => {
    if (a.shop._id === preferredShop) return -1
    if (b.shop._id === preferredShop) return 1
    return 0
  })

  const prodImage = dataItems[0]?.productImage

  const itemsPharmacyUniq = [...new Set(dataItems.map(p => p.shop.name))]
  const itemsPharmacyUniqSel = [
    ...new Set(selecteditems?.map(p => p.shop.name)),
  ]
  const itemsCountyUniq = [
    ...new Set(dataItems.map(p => p.shop.physicalAddress.county)),
  ]
  const itemsCountyUniqSel = [
    ...new Set(selecteditems?.map(p => p.shop.physicalAddress.county)),
  ]
  const itemsEstateUniq = [
    ...new Set(dataItems.map(p => p.shop.physicalAddress.street)),
  ]
  const itemsEstateUniqSel = [
    ...new Set(selecteditems?.map(p => p.shop.physicalAddress.street)),
  ]

  const FilteredPharmacy = items =>
    pharmacy.length > 0
      ? items.filter(item => pharmacy.indexOf(item.shop.name) > -1)
      : items

  const FilteredCounty = items =>
    countyLoc.length > 0
      ? items.filter(
        item => countyLoc.indexOf(item.shop.physicalAddress.county) > -1,
      )
      : items

  const FilteredEstate = items =>
    estateLoc.length > 0
      ? items.filter(
        item => estateLoc.indexOf(item.shop.physicalAddress.street) > -1,
      )
      : items

  const handleSelectPharmacy = (e, item) => {
    if (e.target.checked) {
      pharmacy.push(item)
    } else {
      const index = pharmacy.indexOf(item)
      pharmacy.splice(index, 1)
    }
    const filteredDosageForm = FilteredPharmacy(dataItems)
    setSelecteditems(filteredDosageForm)
  }

  const handleSelectCounty = (e, item) => {
    if (e.target.checked) {
      countyLoc.push(item)
    } else {
      const index = countyLoc.indexOf(item)
      countyLoc.splice(index, 1)
    }
    const filteredDosageForm = FilteredPharmacy(dataItems)
    const filteredDosageFormStrength = FilteredCounty(filteredDosageForm)
    setSelecteditems(filteredDosageFormStrength)
  }

  const handleSelectEstate = (e, item) => {
    if (e.target.checked) {
      estateLoc.push(item)
    } else {
      const index = estateLoc.indexOf(item)
      estateLoc.splice(index, 1)
    }
    const filteredDosageForm = FilteredPharmacy(dataItems)
    const filteredDosageFormStrength = FilteredCounty(filteredDosageForm)
    const filteredDosageFormStrengthApi = FilteredEstate(
      filteredDosageFormStrength,
    )
    setSelecteditems(filteredDosageFormStrengthApi)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = e => {
    setIsOpen(false)
  }

  const UncheckAll = check => {
    let i
    for (i = 0; i < check.length; i++) check[i].checked = false
    setPharmacy([])
    setCountyLoc([])
    setEstateLoc([])
  }

  const keyword = productName
    ? `${productName.replace('percent', '%')} price in Kenya - Afyabook`
    : 'medication in Kenya - Afyabook, Healthcare at your fingertip'
  const keyword2 = productName
    ? `${productName} in Kenya - price, brand names, uses`
    : 'medication in Kenya - price, brand names, uses'
  const keyword3 = productName
    ? `${productName} price in Kenya`
    : 'medication price in Kenya'
  const keyword4 = productName || 'medication'
  const keyword5 = productName
    ? `${productName} tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, `
    : 'medication tanzania,somalia,uganda,rwanda,zambia,congo,ethiopia,burundi,nigeria,ghana,egypt,senegal,botswana,togo,south africa,malawi, congo - kinshasa,cameroon,zimbabwe,congo - brazzaville,benin,angola,djibouti,mali,namibia, '
  const keyword_desc = productName
    ? `${productName.replace(
      'percent',
      '%',
    )} brand in Kenya. Order or upload prescriptions to pharmacy, pay and get your medications delivered`
    : 'medication brand in Kenya. Order and upload prescriptions to the pharmacy near you, pay and get your medications delivered'
  const siteurl = `https://www.afyabook.com/search/${productName
    ?.replace(/ /g, '-')
    .toLowerCase()}`

  return (
    <>
      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword2 + keyword3 + keyword5 + keyword4}
        img={prodImage || Drugicon}
        siteUrl={siteurl}
        canonical={siteurl}
      />
      <PageNav />
      <>
        <header className="header" ref={headerRef}>
          <p className="header-title">Stop looking for an item — find it.</p>
        </header>
        <div style={{ textAlign: 'center' }}>
          Different pharmacies sell at different prices
        </div>

        <Btn onClick={openModal}>Filter</Btn>
        <main className="container" ref={containerRef}>
          <div className="container-wrapper">
            <section className="container-filters">
              <div className="container-header">
                <h2>Filters</h2>
                <div
                  style={{
                    fontSize: '20px',
                    margin: '1rem',
                    cursor: 'pointer',
                    background: 'red',
                    borderRadius: '10px',
                    textAlign: 'center',
                    color: 'white',
                    padding: '5px',
                  }}
                  onClick={() => {
                    isBrowser &&
                      UncheckAll(window.document.getElementsByName('filter'))
                    setSelecteditems(dataItems)
                  }}
                >
                  Clear
                </div>
              </div>

              <div className="container-body">
                {/* <div header="Pharmacy">
                  <FilterBox>
                    <h6>PHARMACY</h6>
                    {countyLoc.length || estateLoc.length > 0 ? itemsPharmacyUniqSel.map((item, index) => {
                      const isSelected = pharmacy.length > 0 ? pharmacy.includes(item) : false
                      return (
                        <span key={index} style={{ margin: "5px" }}>
                          <input
                            type="checkbox"
                            name="filter"
                            checked={isSelected}
                            onChange={(e) => handleSelectPharmacy(e, item)}
                            style={{ marginRight: "1em" }}
                          />
                          {item}
                        </span>
                      )
                    })
                      : itemsPharmacyUniq.map((item, index) => {
                        const isSelected = pharmacy.length > 0 ? pharmacy.includes(item) : false
                        return (<span key={index} style={{ margin: "5px" }}>
                          <input
                            type="checkbox"
                            name="filter"
                            checked={isSelected}
                            onChange={(e) => handleSelectPharmacy(e, item)}
                            style={{ marginRight: "1em" }}
                          />
                          {item}
                        </span>
                        )
                      })
                    }
                  </FilterBox>
                </div> */}
                <div header="County">
                  <FilterBox>
                    <h6>COUNTY</h6>
                    {pharmacy.length || estateLoc.length > 0
                      ? itemsCountyUniqSel.map((item, index) => {
                        const isSelected =
                          countyLoc.length > 0
                            ? countyLoc.includes(item)
                            : false
                        return (
                          <span key={index} style={{ margin: '5px' }}>
                            <input
                              type="checkbox"
                              name="filter"
                              checked={isSelected}
                              onChange={e => handleSelectCounty(e, item)}
                              style={{ marginRight: '1em' }}
                            />
                            {item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase()}
                          </span>
                        )
                      })
                      : itemsCountyUniq.map((item, index) => {
                        const isSelected =
                          countyLoc.length > 0
                            ? countyLoc.includes(item)
                            : false
                        return (
                          <span key={index} style={{ margin: '5px' }}>
                            <input
                              type="checkbox"
                              name="filter"
                              checked={isSelected}
                              onChange={e => handleSelectCounty(e, item)}
                              style={{ marginRight: '1em' }}
                            />
                            {item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase()}
                          </span>
                        )
                      })}
                  </FilterBox>
                </div>
                <div header="Street/Estate">
                  <FilterBox>
                    <h6>STREET/ESTATE</h6>
                    {pharmacy.length || countyLoc.length > 0
                      ? itemsEstateUniqSel.map((item, index) => {
                        const isSelected =
                          estateLoc.length > 0
                            ? estateLoc.includes(item)
                            : false
                        return (
                          <span key={index} style={{ margin: '5px' }}>
                            <input
                              type="checkbox"
                              name="filter"
                              checked={isSelected}
                              onChange={e => handleSelectEstate(e, item)}
                              style={{ marginRight: '1em' }}
                            />
                            {item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase()}
                          </span>
                        )
                      })
                      : itemsEstateUniq.map((item, index) => {
                        const isSelected =
                          estateLoc.length > 0
                            ? estateLoc.includes(item)
                            : false
                        return (
                          <span key={index} style={{ margin: '5px' }}>
                            <input
                              type="checkbox"
                              name="filter"
                              checked={isSelected}
                              onChange={e => handleSelectEstate(e, item)}
                              style={{ marginRight: '1em' }}
                            />
                            {item.charAt(0).toUpperCase() +
                              item.slice(1).toLowerCase()}
                          </span>
                        )
                      })}
                  </FilterBox>
                </div>
              </div>
            </section>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {selecteditems.length > 0
              ? selecteditems
                .filter(item => item.showProduct)
                .map((item, index) => (
                  <div style={{ width: '250px', margin: '1rem' }} key={index}>
                    {HitItem(item, setSuccess)}
                  </div>
                ))
              : dataItems
                .filter(item => item.showProduct)
                .map((item, index) => (
                  <div style={{ width: '250px', margin: '1rem' }} key={index}>
                    {HitItem(item, setSuccess)}
                  </div>
                ))}
          </div>
        </main>
      </>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Refinements"
        css={`
          width: 80%;
          margin-right: auto;
          margin-left: auto;
          margin-top: 10rem;
          background: #e4e4e4;
          border: 3px solid black;
          padding: 1rem 1rem;
          border-radius: 10px;
          overflow-y: scroll;
          height: -webkit-fill-available;

          @media screen and (min-width: 600px) {
            width: 70%;
          }

          @media screen and (min-width: 1024px) {
            display: none;
          }
        `}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Btn2 onClick={closeModal}>Close</Btn2>
          <Btn2
            style={{ background: 'red' }}
            onClick={() => {
              isBrowser &&
                UncheckAll(window.document.getElementsByName('filter'))
              setSelecteditems(dataItems)
            }}
          >
            Clear
          </Btn2>
        </div>
        <FilterWrapperM>
          {/* <FilterBoxM>
            <p>PHARMACY</p>
            {selecteditems.length > 0 ? itemsPharmacyUniqSel.map((item, index) => {
              const isSelected = pharmacy.length > 0 ? pharmacy.includes(item) : false
              return (
                <span key={index} style={{ margin: "5px" }}>
                  <input
                    type="checkbox"
                    name="filter"
                    checked={isSelected}
                    onChange={(e) => handleSelectPharmacy(e, item)}
                    style={{ marginRight: "1em" }}
                  />
                  {item}
                </span>
              )
            })
              : itemsPharmacyUniq.map((item, index) => {
                const isSelected = pharmacy.length > 0 ? pharmacy.includes(item) : false
                return (<span key={index} style={{ margin: "5px" }}>
                  <input
                    type="checkbox"
                    name="filter"
                    checked={isSelected}
                    onChange={(e) => handleSelectPharmacy(e, item)}
                    style={{ marginRight: "1em" }}
                  />
                  {item}
                </span>
                )
              })
            }
          </FilterBoxM> */}
          <FilterBoxM>
            <p>COUNTY</p>
            {selecteditems.length > 0
              ? itemsCountyUniq.map((item, index) => {
                const isSelected =
                  countyLoc.length > 0 ? countyLoc.includes(item) : false
                return (
                  <span key={index} style={{ margin: '5px' }}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={e => handleSelectCounty(e, item)}
                      style={{ marginRight: '1em' }}
                    />
                    {item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase()}
                  </span>
                )
              })
              : itemsCountyUniq.map((item, index) => {
                const isSelected =
                  countyLoc.length > 0 ? countyLoc.includes(item) : false
                return (
                  <span key={index} style={{ margin: '5px' }}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={e => handleSelectCounty(e, item)}
                      style={{ marginRight: '1em' }}
                    />
                    {item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase()}
                  </span>
                )
              })}
          </FilterBoxM>
          <FilterBoxM>
            <p>ESTATE/STREET</p>
            {selecteditems.length > 0
              ? itemsEstateUniq.map((item, index) => {
                const isSelected =
                  estateLoc.length > 0 ? estateLoc.includes(item) : false
                return (
                  <span key={index} style={{ margin: '5px' }}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={e => handleSelectEstate(e, item)}
                      style={{ marginRight: '1em' }}
                    />
                    {item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase()}
                  </span>
                )
              })
              : itemsEstateUniq.map((item, index) => {
                const isSelected =
                  estateLoc.length > 0 ? estateLoc.includes(item) : false
                return (
                  <span key={index} style={{ margin: '5px' }}>
                    <input
                      type="checkbox"
                      name="filter"
                      checked={isSelected}
                      onChange={e => handleSelectEstate(e, item)}
                      style={{ marginRight: '1em' }}
                    />
                    {item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase()}
                  </span>
                )
              })}
          </FilterBoxM>
        </FilterWrapperM>
      </Modal>
      <BotNav />
      <Footer />
    </>
  )
}

export default Search

const Btn = styled.button`
  background: green;
  width: 90px;
  height: 30px;
  position: fixed;
  bottom: 4rem;
  margin-right: 65%;
  margin-left: 35%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 10px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const Btn2 = styled.button`
  background: green;
  width: 90px;
  height: 30px;
  bottom: 1rem;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem 1rem;
  border-radius: 10px;
`

const FilterBox = styled.div`
  max-height: 400px;
  height: 100%;
  overflow: auto;
  color: #000;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    border-radius: 10px;
  }
`

const FilterWrapperM = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  list-style: none;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const FilterBoxM = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 1rem;
  color: #000;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const ImgContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`
const ImgContainerM = styled.div`
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
`
